.login-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  background: no-repeat;
/*  background-size: cover;*/

  .bg-img{
    position: fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
  }

  .title {
    font-size: 6vw;
    font-weight: bold;
    margin-top: 15vh;

  }

  .sub-title {
    margin-top: 10px;
    font-size: 3vw;

  }
.logo{
  position:fixed;
  right: 10vw;
  top:20px;
  z-index: 100;
  width: 30vw;
  img{
    width: 100%;
  }
}
  .log {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
.btn-center{
  z-index: 100;
  position: relative;
}
  .btn-in {
    margin-top: 15vh;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: 100%;


  }
  .btn-in:hover{
    cursor: pointer;
  }

  .footer {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }

}